<template>
    <tr class="border-b border-solid border-light-border my-4">
        <td class="sm:py-2 px-2 sm:px-4 text-center text-xs font-normal leading-tight relative align-middle">
            {{ index + 1 }}
            <div v-if="positionChange !== 0 && showPositionChange"
                 class="absolute sm:left-8 sm:bottom-2 md:left-12 flex items-center">
                <Icon :icon-name="positionChange > 0 ? 'ratingUp' : 'ratingDown'" />
                <span class="text-[8px] font-normal leading-tight">
                    {{ positionChange > 0 ? `+${positionChange}` : positionChange }}
                </span>
            </div>
        </td>
        <td class="sm:py-2 px-2 sm:px-4 text-xs font-normal leading-tight align-middle">{{ data.name + " " + data.surname }}</td>
        <td class="sm:py-2 px-2 sm:px-4 text-xs font-bold leading-tight align-middle">{{ data.score }}</td>
        <td class="text-center text-xs font-normal leading-tight align-middle">
            <div class="my-2 mx-2 sm:mx-4 bg-[#F3E8D0] rounded-lg py-2">
                {{ data.gold }}
            </div>
        </td>
        <td class="text-center text-xs font-normal leading-tight align-middle">
            <div class="my-2 mx-2 sm:mx-4 bg-[#DFEBF4] rounded-lg py-2">
                {{ data.silver }}
            </div>
        </td>
        <td class="text-center text-xs font-normal leading-tight align-middle">
            <div class="my-2 mx-2 sm:mx-4 bg-[#E4D8D0] rounded-lg py-2">
                {{ data.bronze }}
            </div>
        </td>
    </tr>
</template>

<script setup>
import { computed } from 'vue';
import Icon from "@/views/components/helpers/icon.vue";

const props = defineProps({
    data: {
        type: Object,
        required: false,
    },
    index: {
        type: Number,
        required: false,
    },
    showPositionChange: {
        type: Boolean,
        default: true,
    }
});

const positionChange = computed(() => {
    if (props.data.position != null && props.data.previous_position != null) {
        return props.data.previous_position - props.data.position;
    }
    return 0;
});

</script>