<template>

    <div class="w-full">
        <div class=" mt-4 block sm:flex justify-between">

            <input-custom label="Количество регистраций*" type="number" v-model="fd.qnt" class="mt-4 mob_margin"
                          labelClass="pass-update-offer" input-class="sm:w-44"
                          :min="1" :max="90"/>

            <input-percent label="Процент скидки*" v-model="fd.discount" class="mt-4 mob_margin"
                           labelClass="pass-update" input-class="sm:w-44"/>


        </div>
    </div>

    <alerts alertType="FewRegistration"/>

    <button class="btn mt-8 btn_blue w-full sm:w-64 d_center" @click="saveCompetitionCondition" :disabled="isLoading">
        <icon icon-name="spinner_white" class="mr-3" v-if="isLoading" />
        Сохранить
    </button>


</template>

<script setup>
import {onMounted, ref, watch} from "vue";
import useEmitter from "@/utils/useEmitter";
import Icon from "@/views/components/helpers/icon.vue";
import {useRoute} from "vue-router";
import VueMultiselect from "vue-multiselect";
import DatePicker from "@/views/components/inputComponent/datePicker.vue";
import InputCustom from "@/views/components/inputComponent/inputCustom.vue";
import {create} from "axios";
import InputPercent from "@/views/components/inputComponent/inputPercent.vue";
import Alerts from "@/views/components/helpers/alerts.vue";
import {apiRequest} from "@/utils/helpers";
import {EVENTS} from "@/router/api";

const props = defineProps({
    data: {
        type: Object,
        default: () => ({})
    },
    offerType: {
        type: String,
        default: 'few_reg_sportsman'
    }
})

const route = useRoute();
const emitter = useEmitter();
const isLoading = ref(false);
const fd = ref({
    type: null,
    qnt: null,
    discount: null
});


const validation = () => {
    let base = 'Проверьте корректность ввода поля '
    if (fd.value.qnt < 1) return emitter.emit("alert", {alertType: 'FewRegistration', message: base + ' Колличество участников'});
    if (fd.value.discount < 1 || fd.value.discount > 100) return emitter.emit("alert", {alertType: 'FewRegistration', message: base + ' Процент скидки'});
    return true;
}

const saveCompetitionCondition = () => {
    if (validation()) {
        isLoading.value = true;

        apiRequest(EVENTS + '/' + route.params.id, fd.value, 'put')
            .then(resp => {
                if (resp && resp.success && resp.message) {
                    emitter.emit("success", {alertType: 'FewRegistration', message: resp.message});
                }
            })
            .catch(e => {
                if(e.message) {
                    emitter.emit("alert", {alertType: 'FewRegistration', message: e.message});
                } else {
                    emitter.emit("alert", {alertType: 'FewRegistration', message: 'Ошибка сервера! Обратитесь в поддержку или повторите позже.'});
                }
                console.log('error', e)
            })
            .finally(() => {
                isLoading.value = false;
            });

    }
}

onMounted(() => {
    fd.value.type = props.offerType;
    fd.value.qnt = props.data.discounts[0]?.registration_quantity;
    fd.value.discount = props.data.discounts[0]?.discount_percent;
});
</script>