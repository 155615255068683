<template>
    <div v-if="data.discount_price">
        <div :class="{'text-sm': !store.state.isMobile, 'text-s': store.state.isMobile}"
             class="text-gray-500 line-through">
            {{ getFormatPriceData(data.direction_category_price) }}
        </div>
        <div class="font-bold">
            {{ getFormatPriceData(data.discount_price) }}
        </div>
    </div>
    <div v-else>
        {{ getFormatPriceData(data.direction_category_price) }}
    </div>

</template>

<script setup>
import store from "@/store";

const props = defineProps({
    data: {
        type: Object,
        required: true,
    },
})

const getFormatPriceData = (el) => {
    return parseFloat(el).toLocaleString() + ' ₽';
}

</script>
