<template>
    <dashboardLayout>

        <modal :visible="modalError">
            <div class="text-left text-danger-dark font-bold text-lg">Ошибка!</div>
            <div class="text-sm mt-5">{{ modalErrorMessage }}</div>
            <button class="btn btn_blue w-full mt-5" @click="modalError = false">Понятно</button>
        </modal>

        <navigation-container class="mt-10">

            <wrap-section :is-empty="true">
                <h2 class="text-2xl sm:text-4xl font-semibold text-dark-base">
                    Регистрация спортсменов
                </h2>
                <div class="mt-5 mb-5 text-lg hidden sm:block">
                    После завершения регистрации вам потребуется оплатить участие спортсменов. После оплаты все зарегистрированные участники появятся в разделе «Мои соревнования», где вы сможете загрузить необходимые файлы для участия в соревновании.
                </div>

                    <registration-p-c :registrations="registrations" v-if="registrations.length" class="hidden sm:block"/>
                    <registration-mob :registrations="registrations" v-if="registrations.length" class="sm:hidden"/>

            </wrap-section>

            <div v-if="isRegistrationAvailable">
                <registration-input class="mt-7" :registrations="registrations" @error:registrations="errorRegistrations" @update:registrations="updateRegistrations" />
            </div>
            <div v-else>
                <div class="flex flex-col sm:flex-row sm:justify-between mt-7">
                    <div class="w-full sm:w-1/3 order-2 sm:order-1 mt-4 sm:mt-0">
                        <div>
                            <button class="btn header_auth btn_white d_center !text-sm p-3 w-full "
                                    @click="router.push({name: 'payment-event', params: {id: route.params.id}})">
                                <icon icon-name="payment" color-var="--base-color" class="mr-3" hover-var="--dark-base"/>
                                Перейти к оплате
                            </button>
                        </div>

                    </div>
                </div>
            </div>

            <div class="mt-6 text-sm font-normal leading-5" v-if="availableRegistrationPositions !== null && availableRegistrationPositions >= 0">
                Осталось свободных мест для регистрации на соревнование: 
                <span class="text-dark-base font-bold">{{ availableRegistrationPositions }}</span> 
                <span class="text-icon-base"> / {{ totalRegistrationPositions }}</span>
            </div>

        </navigation-container>
    </dashboardLayout>
</template>

<script setup>
import WrapSection from "@/views/components/helpers/wrapSection.vue";
import {onMounted, ref, computed} from "vue";
import useEmitter from "@/utils/useEmitter";
import {useRoute} from 'vue-router';
import dashboardLayout from "@/views/layout/dashboard";
import NavigationContainer from "@/views/layout/components/navigation-container.vue";
import RegistrationPC from "@/views/pages/dashboard/registration/registrationPC.vue";
import RegistrationInput from "@/views/pages/dashboard/registration/registrationInput.vue";
import {apiRequest} from "@/utils/helpers";
import {REGISTRATION, EVENTS} from "@/router/api";
import RegistrationMob from "@/views/pages/dashboard/registration/registrationMob.vue";
import Modal from "@/views/components/modal/modal.vue";
import router from "@/router/router";
import Icon from "@/views/components/helpers/icon.vue";

const route = useRoute();
const emitter = useEmitter();
const event = ref(null);
const registrations = ref([]);
const isUnitLimits = ref(false);
const totalRegistrationPositions = ref(null);
const availableRegistrationPositions = ref(null);
const isRegistrationAvailable = ref(false);

const modalError = ref(false);
const modalErrorMessage = ref(null);

const registrationData = async () => {
    await apiRequest(REGISTRATION + "?event_uuid=" + route.params.id,[], 'get')
        .catch(e => {
            console.log('error', e);
        })
        .then(resp => {
            if (resp.error) {
                emitter.emit("alert", resp.message);
            }
            if (resp.success && resp.result) {
                registrations.value = resp.result;
            }
        })
};

const eventData = async () => {
    await apiRequest(`${EVENTS}/${route.params.id}`,[], 'get')
        .catch(e => {
            console.log('error', e)
        })
        .then(resp => {
            if (resp.error) {
                emitter.emit("alert", resp.message);
            }
            if (resp.success && resp.result && resp.result.units_limit) {
                totalRegistrationPositions.value = resp.result.units_limit.value;
                availableRegistrationPositions.value = resp.result.units_limit.available_count;
                (resp.result.units_limit.available_count > 0 ) && (isRegistrationAvailable.value = true);
                isUnitLimits.value = true;
            }
            if (!resp.result.units_limit) {
                isRegistrationAvailable.value = true
            }
        })
};

onMounted(async () => {
    await registrationData();
    await eventData();
});

const errorRegistrations = (error) => {
    console.log('newRegistrations', error);
    
    // Отображаем ошибку в модальном окне
    modalError.value = true;
    modalErrorMessage.value = error + '. Обновите страницу';

    isRegistrationAvailable.value = false;
}

const updateRegistrations = (newRegistrations) => {

    registrations.value = newRegistrations;

    if (isUnitLimits.value) {
        // Проверка количества доступных мест
        if (availableRegistrationPositions.value > 0) {
            availableRegistrationPositions.value -= 1; // Уменьшаем количество доступных мест на 1
        }

        // Проверка на отсутствие доступных мест
        if (availableRegistrationPositions.value <= 0) {
            isRegistrationAvailable.value = false; // Отключаем регистрацию
            emitter.emit("alert", "Регистрация завершена. Все места заняты.");
        }
    }
};

const deleteRegistration = (uuid) => {
    apiRequest(REGISTRATION + "/" + uuid, {uuid: uuid}, 'delete')
        .catch(e => {
            console.log('error', e)})
        .then(resp => {
            if(resp && resp.success && resp.result) {
                registrations.value = registrations.value.filter(reg => reg.uuid !== uuid);
                // Увеличиваем количество доступных мест при удалении
                if (isUnitLimits.value) {
                    availableRegistrationPositions.value += 1;
                    isRegistrationAvailable.value = true; // Обновляем возможность регистрации
                }
            }
        })
}

emitter.on('delete-registration', (data) => {
    if(data.type === 'reg') {
        deleteRegistration(data.uuid);
    }
});

</script>
