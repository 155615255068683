<template>
    <baseLayout>
        <div class="wrap_login d_center">
            <div class="login_form">
                <h1>{{ is_mobile ? "Регистрация" : `Регистрация ${reg_type} c e-champ` }}</h1>

                <div class="my-2">
                    <a class="text-base-color" href="https://help.wafed.org/registratsiya" target="_blank" rel="noopener noreferrer">
                        Как зарегистрироваться на спортивной платформе ОФСОО?
                    </a>
                </div>

                <input-custom
                    label-class="min-w-24"
                    label="E-champ ID*"
                    v-model="formData.e_champ_id"
                    type="text"
                    @input="onEChampIdInput"
                    class="mt-4 mob_margin"
                />

                <button class="btn mt-6 btn_blue w-full" @click="getUserByEChampId">
                    Найти пользователя
                </button>

                <!-- Информация о пользователе -->
                <div v-if="emailFieldVisible && userInfo.full_name">
                    <div class="w-full p-4 bg-green-message rounded-2xl mt-4">
                        <div class="text-center text-sm">
                            {{ userInfo.full_name }} - {{ userInfo.birthday }}
                        </div>
                    </div>
                </div>

                <alerts ref="alertRef" alertType="userReg" />


                <div  v-if="emailFieldVisible">
                    <input-custom label-class="min-w-24"
                    label="Email*" 
                    type="text" 
                    v-model="formData.email" 
                    class="mt-4 mob_margin"/>

                    <button class="btn mt-6 btn_blue w-full" @click="eChampUserupdateEmail">
                        Обновить E-mail и получить пароль
                    </button>
                </div>

                <!-- Сообщение об успешном обновлении -->
                <div v-if="successMessage">
                    <div class="w-full p-4 bg-green-message rounded-2xl mt-4">
                        <div class="text-center text-sm">
                            {{ successMessage }}
                        </div>
                    </div>
                </div>

                <div class="have_account">
                    Уже зарегистрированы?
                    <router-link :to="{ name: 'login' }">Войти</router-link>
                </div>
            </div>
        </div>
    </baseLayout>
</template>

<script setup>
import baseLayout from "@/views/layout/base";
// import {useRoute} from 'vue-router'
import Alerts from "@/views/components/helpers/alerts.vue";
import InputCustom from "@/views/components/inputComponent/inputCustom.vue";
import {ref} from "vue";
import useEmitter from "@/utils/useEmitter";
import {apiRequest} from "@/utils/helpers";
import {GET_USER_BY_ECHAMP_ID, ECHAMP_USER_UPDATE_EMAIL} from "@/router/api";

const emitter = useEmitter();
// const route = useRoute();

const alertRef = ref(null);

const successMessage = ref('');

const formatDate = (isoDateString) => {
    const date = new Date(isoDateString);
    return date.toLocaleDateString('ru-RU');
};

const emailFieldVisible = ref(false);

const formData = ref({
    e_champ_id: '',
    email: '',
});

const userInfo = ref({
    full_name: '',
    birthday: '',
    status: ''
});


let reg_type = '';

const is_mobile = window.innerWidth < 576;

const validation = () => {
    let base = 'Проверьте корректность ввода поля '
    // if (formData.value.e_champ_id.length < 5) {
    //     return emitter.emit("alert", "Проверьте E-Champ ID, должно быть 5 символов.");
    // }

    // if (formData.value.email.length < 4) {
    //     return emitter.emit("alert", base + "email");
    // }

    return true;
}

const getUserByEChampId = async () => {

    try {
        const resp = await apiRequest(GET_USER_BY_ECHAMP_ID + `?e_champ_id=${formData.value.e_champ_id}`, {}, 'get');
        
        if (resp && resp.success) {
            const user = resp.result;

            if (user.status !== 'echamp') {
                emitter.emit("alert", "Пользователь уже обновлён.");
                emailFieldVisible.value = false;
                return;
            }

            userInfo.value.full_name = user.full_name || 'Имя не указано';
            userInfo.value.birthday = user.birthday ? formatDate(user.birthday) : 'Дата рождения не указана';
            userInfo.value.status = user.status || '';
            emailFieldVisible.value = true;
        } else {
            emitter.emit("alert", "Пользователь с данным E-champ ID не найден");
            userInfo.value.full_name = '';
            userInfo.value.birthday = '';
            userInfo.value.status = '';
            emailFieldVisible.value = false;
        }
    } catch (e) {
        console.error('Ошибка при запросе:', e);
        emitter.emit("alert", e.message || 'Ошибка при запросе');
        userInfo.value.full_name = '';
        userInfo.value.birthday = '';
        userInfo.value.status = '';
        emailFieldVisible.value = false;
    }
}

const onEChampIdInput = (event) => {
    formData.value.e_champ_id = event.target.value.replace(/\D/g, '');

    // очищение уведомления при изменении текста
    if (alertRef.value) {
        alertRef.value.clear();
    }

}


const eChampUserupdateEmail = async () => {
    if (userInfo.value.status !== 'echamp') {
        emitter.emit("alert", "Ваш профиль не может быть обновлён, вероятно, его уже обновили");
        return;
    }

    try {
        formData.value.email = formData.value.email.trim().toLowerCase();
        const resp = await apiRequest(ECHAMP_USER_UPDATE_EMAIL, formData.value);

        if (resp && resp.success) {
            successMessage.value = resp.result;
            formData.value.email = '';
            userInfo.value.status = 'draft';
        } else {
            emitter.emit("alert", resp.message || "Ошибка при обновлении email.");
            successMessage.value = '';
        }
    } catch (e) {
        console.error('Ошибка при запросе:', e);
        emitter.emit("alert", e.message || 'Ошибка при запросе.');
        successMessage.value = '';
    }
}

console.log('emailFieldVisible:', emailFieldVisible.value);
</script>

<style>
input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 1em;
    height: 1em;
    border: 1px solid var(--light-border);
    border-radius: 3px;
    position: relative;
    cursor: pointer;
}

input[type="checkbox"]:checked {
    border-color: var(--base-color);
    background-color: var(--base-color);
}

input[type="checkbox"]:checked::after {
    content: '✓';
    color: white;
    font-size: 0.75em;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.wrap_age_gender {
    display: flex;
    justify-content: space-between;
}

.have_account {
    margin-top: 20px;
    text-align: center;
    color: var(--base-color);
}

.have_account a {
    color: var(--base-color);
}

@media (max-width: 639px) {
    .wrap_age_gender {
        display: block;
    }
}

@media (max-width: 576px) {
    .gender {
        margin-left: unset;
    }
}

</style>
