<template>
    <dashboardLayout>
        <navigation-container class="mt-10">

            <wrap-section v-if="eventInfo">
                <div v-if="eventInfo.banner_url">
                    <img :src="eventInfo.banner_url" alt="" class="rounded-2xl">
                </div>
                <div class="text-2xl font-normal leading-7 mt-5">
                    {{eventInfo.title}}
                </div>
                <div class="text-dark-base text-xl mt-5 font-bold">
                    {{eventInfo.direction?.name}}
                </div>


            </wrap-section>

            <wrap-section :is-empty=true>
                <h2 class="text-xl font-bold md:text-4xl text-dark-base">Файлы</h2>
            </wrap-section>

            <comp-pc class="hidden mi:block" :data="FilesListWidthShortedFileNames"/>
            <comp-mob class="mi:hidden" :data="FilesListWidthShortedFileNames"/>
            <br><br>
        </navigation-container>
    </dashboardLayout>
</template>

<script setup>
import WrapSection from "@/views/components/helpers/wrapSection.vue";
import CompPc from "@/views/components/competitions/compPC.vue";
import CompMob from "@/views/components/competitions/compMob.vue";
import {SPORTSMANS_FILES_UPLOAD} from "@/router/api";
import {apiRequest} from "@/utils/helpers";
import {onMounted, ref, computed} from "vue";
import useEmitter from "@/utils/useEmitter";
import {useRoute} from 'vue-router';
import dashboardLayout from "@/views/layout/dashboard";
import NavigationContainer from "@/views/layout/components/navigation-container.vue";

const route = useRoute();
const emitter = useEmitter();
const sportsmanFilesUploadList = ref(null);
const eventInfo = ref(null);
const competitionId = ref(route.params.id);
const isSportsmanRegistration = ref('');

onMounted(async () => {
    isSportsmanRegistration.value = route.meta.sportsmanRegistration ? '&sportsman_registration=true' : '';
    await compFilesUpload();
});
const compFilesUpload = async () => {
    await apiRequest(SPORTSMANS_FILES_UPLOAD + '?event_id=' + competitionId.value + isSportsmanRegistration.value, null, 'get')
        .catch(e => {
            console.log('error', e)
        })
        .then(resp => {
            if (resp && resp.success && resp.result?.data) {
                sportsmanFilesUploadList.value = resp.result.data
                eventInfo.value = resp.result.event
            }
        })
}
emitter.on('fileSportsmanUpload', value => {
    if (!value) return;
    sportsmanFilesUploadList.value = value;
})


// обрезка названия файла до 10 символов
const shortenFileName = (fileName) => {
    if (!fileName) return ''; // проверка на имя файла
    const dotIndex = fileName.lastIndexOf('.'); // позиция точки
    const extension = fileName.slice(dotIndex); // расширение
    const name = fileName.slice(0, dotIndex); // имя файла без расширения
    
    // обрезка имя файла до 9 символов, 10-й символ остаётся для расширения
    return name.length > 9 ?  `${name.slice(0, 9)}..${extension}` : fileName;
};

// сокращение названий файлов
const FilesListWidthShortedFileNames = computed(() => {
    // проверка наличия файлов
    if (!sportsmanFilesUploadList.value || sportsmanFilesUploadList.value.length === 0) {
        return [];
    }

    // извлечение из элементов ориг имя
    return sportsmanFilesUploadList.value.map(item => {
        const updatedMediaFiles = Object.entries(item.media_files).reduce((acc, [key, file]) => {
            // проверка, есть ли файл и его имя
            if (file && file.file_name) {
                acc[key] = {
                    ...file,
                    short_file_name: shortenFileName(file.file_name) // сокр имя файла и добавление его в объект
                };
            } else {
                acc[key] = file; // если файл отсутствует, сохраняем его как есть
            }
            return acc;
        }, {});

        return {
            ...item,
            media_files: updatedMediaFiles // media_files с оригинальными и сокращёнными именами
        };
    });
});

</script>
