<template>
    <thead>
        <tr class="border-b border-solid border-light-border bg-light-base text-xs font-normal leading-tight">
            <th class="sm:py-2 px-2 sm:px-4 text-left hidden sm:table-cell max-w-9 text-dark-base">Место</th>
            <th class="sm:py-2 px-2 sm:px-4 text-left sm:hidden text-dark-base"></th>
            <th class="py-2 w-2/5 px-4 text-left text-dark-base">ФИО</th>
            <th class="w-1/8 sm:py-2 px-2 sm:px-4 text-left text-dark-base flex-1" @click="sort('score')">
                <span class="flex items-center cursor-pointer" :class="{ 'font-bold': refActiveTab === 'score' }">
                    <icon :icon-name="refActiveTab === 'score' ? 'sortingBold' : 'sorting'" class="mr-1 sm:mr-2"/>
                    Баллы
                </span>
            </th>
            <header-item label-name="Золото" iconNameProp="gold" :is-active="refActiveTab === 'gold'" @click="sort('gold')"/>
            <header-item label-name="Серебро" iconNameProp="silver" :is-active="refActiveTab === 'silver'" @click="sort('silver')"/>
            <header-item label-name="Бронза" iconNameProp="bronze" :is-active="refActiveTab === 'bronze'" @click="sort('bronze')"/>
        </tr>
    </thead>
</template>
<script setup>
import Icon from "@/views/components/helpers/icon.vue";
import headerItem from "@/views/components/raiting/headerItem.vue";
import useEmitter from "@/utils/useEmitter";
import { ref, defineProps, watch, onMounted } from 'vue';

const props = defineProps({
    activeTab: {
        type: String,
        default: ''
    }
});

const emitter = useEmitter();

const refActiveTab = ref('');

const sort = (type) => {
    refActiveTab.value = type;
    emitter.emit("sort", type);
}

onMounted(() => {
    refActiveTab.value = props.activeTab;
});

watch(() => props.activeTab, (newVal) => {
    refActiveTab.value = newVal;
});



</script>