<template>
    <dashboardLayout>
        <navigation-container class="mt-10">
            <wrap-section :is-empty="true">
                <div>
                    <div class="flex-1 flex items-center">
                        <h2 class="text-4xl">Оплата соревнований</h2>
                    </div>
                </div>

                <Tabs>
                    <Tab active="true" title="Предстоящие" :total="upcomingEvents.pagination?.total">
                        <wrap-section :is-empty=true class="mx-auto py-4 grid grid-cols-1 sm:grid-cols-2 gap-4"
                                      v-if="upcomingEvents.data && upcomingEvents.data.length">
                            <event-card :data="el" v-for="el in upcomingEvents.data" :key="el.uuid"/>
                        </wrap-section>
                        <wrap-section :is-empty=true class="mx-auto py-4" v-if="upcomingEvents?.pagination">
                            <pagination  :model-value="upcomingEventsCurrentPage" :meta="upcomingEvents.pagination"/>
                        </wrap-section>

                    </Tab>
                    <Tab title="Архив" :total="pastEvents.pagination?.total">
                        <wrap-section :is-empty=true class="mx-auto py-4 grid grid-cols-1 sm:grid-cols-2 gap-4"
                                      v-if="pastEvents.data && pastEvents.data.length">
                            <event-card :data="el" v-for="el in pastEvents.data" :key="el.uuid"/>
                        </wrap-section>
                        <wrap-section :is-empty=true class="mx-auto py-4 grid" v-if="pastEvents.pagination">
                            <pagination  :meta="pastEvents.pagination" :model-value="pastEventsCurrentPage"/>
                        </wrap-section>
                    </Tab>
                </Tabs>
            </wrap-section>
        </navigation-container>
    </dashboardLayout>
</template>

<script setup>

import dashboardLayout from "@/views/layout/dashboard";
import Tabs from "@/views/components/helpers/Tabs.vue";
import Tab from "@/views/components/helpers/Tab.vue";
import WrapSection from "@/views/components/helpers/wrapSection.vue";
import NavigationContainer from "@/views/layout/components/navigation-container.vue";
import {apiRequest} from "@/utils/helpers";
import {EVENTS} from "@/router/api";
import {onMounted, ref, watch} from "vue";
import {useRoute} from 'vue-router';
import Pagination from "@/views/components/helpers/pagination.vue";
import EventCard from "@/views/pages/dashboard/payment/eventCard.vue";
import store from "@/store";
import useEmitter from "@/utils/useEmitter";
import {getUpcomingEvents} from "@/utils/helpers";

const route = useRoute();
const emitter = useEmitter();
const eventId = ref(false);
const upcomingEventsCurrentPage = ref(1);
const pastEventsCurrentPage = ref(1);
const upcomingEvents = ref({ data: [], pagination: {} });
const pastEvents = ref({ data: [], pagination: {} });

const getPastEvents = () => {
    apiRequest(EVENTS + "?event_time=past&self=true&page=" + pastEventsCurrentPage.value, null, 'get')
        .catch(e => {
            console.log('error', e)
        })
        .then(resp => {
            if (resp && resp.success) {
                pastEvents.value.data = resp.result.data || [];
                pastEvents.value.pagination = resp.result.pagination || false;
            }
        })
}

onMounted(() => {
    if (route.query.event_id) {
        eventId.value = route.query.event_id;
        return;
    }
    upcomingEvents.value.data = store.state.upcomingEvents;
    getUpcomingEvents(1, true);
    getPastEvents();
});
emitter.on('upcomingEvents', value => {
    if(value.data) {
        upcomingEvents.value.data = value.data;
        upcomingEvents.value.pagination = value.pagination;
    }
})

watch(upcomingEventsCurrentPage, (newPage) => {
    getUpcomingEvents();
});
watch(pastEventsCurrentPage, (newPage) => {
    getPastEvents();
});


</script>
