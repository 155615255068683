<template>
    <dashboardLayout>
        <navigation-container class="mt-10">

            <wrap-section v-if="event">
                <img class="rounded-lg" :src="event.banner_url" alt="">

                <h1 class="text-2xl font-normal text-left pt-5">
                    {{ event.title }}
                </h1>

                <h2 class="text-lg font-semibold text-dark-base pt-5">
                    {{ event.direction.name }}
                </h2>

                <timer :registrationStartAt="event.registration_start_at" :registrationEndAt="event.registration_end_at"
                       :eventStartAt="event.start_at" :eventEndAt="event.end_at"
                />

            </wrap-section>

            <wrap-section :is-empty=true>
                <div v-if="registrations.length">
                    <h2 class="text-dark-base text-lg md:text-xl font-semibold leading-tight">Оплата</h2>

                    <payment-p-c :registrations="registrations" class="hidden sm:block" :event="event"/>

                    <payment-mob :registrations="registrations" class="sm:hidden" :event="event"/>

                    <alerts alertType="paymentEvent" />

                    <div class="block sm:flex justify-between mt-7" v-if="total > 0">
                        <div class="block sm:d_center">
                            <div class="text-xl font-normal leading-relaxed">
                                <div class="flex">
                                    <div class="mr-5">
                                        Итого: <span class="font-bold text-dark-base"></span>
                                    </div>

                                    <div v-if="total_discount">
                                        <div class="flex">
                                            <div class="line-through text-grey-text" >
                                                {{total.toLocaleString()}} ₽
                                            </div>
                                            <div class="ml-5 font-bold">
                                                {{total_discount.toLocaleString()}} ₽
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else>
                                        {{total.toLocaleString()}} ₽
                                    </div>

                                </div>
                                <div v-if="total_discount" class="text-sm">
                                    Применена скидка "{{discount_info?.title}}"
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr class="border-light-border my-8">

                    <div v-if="!isCashPayment && !allRegPaid">
                        

                        <h2 class="text-dark-base text-lg md:text-xl font-semibold leading-tight">Выберите способ оплаты:</h2>

                        <div class="flex items-center space-x-4 md:space-x-12  mt-8">
                            <RadioButton
                                id="cardPayment"
                                value="card"
                                :modelValue="selectedPayment"
                                label="Оплата картой"
                                @update:modelValue="selectedPayment = $event" 
                            />
                            <RadioButton
                                id="cashPayment"
                                value="cash"
                                :modelValue="selectedPayment"
                                label="Оплата наличными"
                                @update:modelValue="selectedPayment = $event" 
                            />
                        </div>

                        <div class="mt-8">
                            <!-- инфо о выбранном методе оплаты -->
                            <div v-if="selectedPayment === 'card'">
                            <p class="text-sm font-normal">Если вы выбираете онлайн-оплату - вы указываете информацию о своем платежном средстве в специальной защищенной форме оплаты на странице компании-партнера. </p>

                            <p class="text-sm font-normal mt-6">К оплате принимаются банковские карты и переводы через СБП.</p>
                            <div class="block sm:w-[260px]">
                                <!-- Кнопка оплаты -->
                                    <div class="w-full mt-8">
                                        <button class="btn header_auth btn_blue d_center !text-sm p-3 w-full cursor-pointer" @click="payInit" :disabled="isLoading">
                                            <icon icon-name="spinner" class="mr-3" v-if="isLoading" />
                                            <icon icon-name="payment" class="mr-3" v-else/>
                                        Оплатить онлайн
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div v-if="selectedPayment === 'cash'">
                                <p class="text-sm font-normal">Вы можете оплатить соревнование наличными непосредственно перед началом самого соревнования. Информация о времени и месте платежа будет отправлена на электронную почту.</p>

                                <div class="block sm:w-[260px]">
                                <!-- Кнопка оплаты -->
                                    <div class="w-full mt-8">
                                        <button class="btn header_auth btn_blue d_center !text-sm p-3 w-full cursor-pointer" @click="updateCashPaymentStatus" :disabled="isLoading">
                                            Оплачу наличными позже
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div v-else-if="isCashPayment">
                        Вы выбрали оплату наличными.
                    </div>
                    <div v-else-if="allRegPaid">
                        Все регистрации оплачены
                    </div>

                </div>
                <div v-else>
                    <h2 class="text-2xl md:text-4xl ">Регистраций не найдено, перейдите по
                        <a class="text-base-color"href="" @click="router.push({name: 'event-registration', params: {id: event.uuid}})">ссылке</a>

                        для регистрации спортсменов</h2>
                </div>

            </wrap-section>

            <Modal :visible="paymentModal">
                <h2 class="text-lg font-semibold">Ваша заявка принята</h2>
                <p class="text-sm font-normal mt-4">Вы сможете оплатить участие наличными организатору соревнований</p>
                <button class="btn header_auth btn_blue d_center !text-sm p-3 w-full cursor-pointer mt-4" @click="closeModal">
                    Перейти на страницу соревнований
                </button>
            </Modal>
        </navigation-container>
    </dashboardLayout>
</template>

<script setup>
import WrapSection from "@/views/components/helpers/wrapSection.vue";
import {onMounted, ref, computed, watch} from "vue";
import useEmitter from "@/utils/useEmitter";
import {useRoute} from 'vue-router';
import dashboardLayout from "@/views/layout/dashboard";
import NavigationContainer from "@/views/layout/components/navigation-container.vue";
import {apiRequest, navigateTo} from "@/utils/helpers";
import {EVENT_PAYMENT, EVENTS, REGISTRATION} from "@/router/api";
import PaymentPC from "@/views/pages/dashboard/payment/paymentPC.vue";
import Timer from "@/views/components/competitions/timer.vue";
import Icon from "@/views/components/helpers/icon.vue";
import PaymentMob from "@/views/pages/dashboard/payment/paymentMob.vue";
import router from "@/router/router";
import Alerts from "@/views/components/helpers/alerts.vue";
import RadioButton from '@/views/components/helpers/RadioButton.vue';
import Modal from '@/views/components/modal/modal.vue';

const selectedPayment = ref('card'); // Состояние для выбранного метода оплаты

const route = useRoute();
const emitter = useEmitter();
const event = ref(null);
const competitionId = ref(route.params.id);
const registrations = ref([]);
const total = ref(0);
const total_discount = ref(0);
const discount_info = ref(null);
const isLoading = ref(false);
const paymentModal = ref(false);
const isSportsmanRegistration = ref('');
const isCashPayment = ref('');
const allRegPaid = ref(false);

const closeModal = () => {
    paymentModal.value = false;
    navigateTo('my-competitions');
};

// Функция для обновления статуса оплаты наличными
const updateCashPaymentStatus = () => {
    const uuidsToUpdate = registrations.value.map(reg => reg.uuid);

    // пока оставил, может снова надо будет менять динамически, галочкой, например
    isCashPayment.value = true;
    paymentModal.value = true;

    // Обновляем состояние is_cash_payment для каждой регистрации
    registrations.value.forEach(reg => {
        reg.is_cash_payment = isCashPayment.value;
    });

    // Отправляем обновленные данные на сервер
    apiRequest(`${REGISTRATION}/update-cash-payment`, { uuids: uuidsToUpdate, is_cash_payment: isCashPayment.value }, 'post')
        .then(resp => {
            if (resp.error) {
                emitter.emit("alert", {
                    alertType: 'paymentEvent',
                    message: 'Ошибка обновления способа оплаты.'
                });
            }
        })
        .catch(e => {
            emitter.emit("alert", { alertType: 'paymentEvent', message: e });
        });
};


const payInit = () => {
    if (!isCashPayment.value) {
        isLoading.value = true;
        apiRequest(EVENT_PAYMENT + "?event_uuid=" + route.params.id  + isSportsmanRegistration.value,[], 'post')
            .then(resp => {
                if (resp.error) {
                    emitter.emit("alert", {alertType: 'paymentEvent',
                        message: 'Ошибка обработки платежа. Обратитесь в службу поддержки или попробуйте позднее'});
                }
                if (resp.success && resp.result) {
                    window.location.href = resp.result;
                }
            })
            .catch(e => {
                emitter.emit("alert", {alertType: 'paymentEvent', message: e});
            })
            .finally(() => {
                isLoading.value = false;
            });
    }
}
const registrationData = async () => {
    await apiRequest(REGISTRATION + "?event_uuid=" + route.params.id + isSportsmanRegistration.value,[], 'get')
        .catch(e => {
            console.log('error', e)
        })
        .then(resp => {
            if (resp.error) {
                emitter.emit("alert", resp.message);
            }
            if (resp.success && resp.result) {
                registrations.value = resp.result;
                // Установить состояние чекбокса в true, если все регистрации наличные
                isCashPayment.value = registrations.value.every(reg => reg.is_cash_payment);
                allRegPaid.value = registrations.value.every(reg => reg.is_paid);
                console.log("ALLREG", allRegPaid.value)
            }
        })
};

onMounted(async () => {
    isSportsmanRegistration.value = route.meta.sportsmanRegistration ? '&sportsman_registration=true' : '';
    await competition();
    await registrationData();
});

const updateRegistrations = (newRegistrations) => {
    registrations.value = newRegistrations;
};

const deleteRegistrationPayment = (uuid) => {
    apiRequest(REGISTRATION + "/" + uuid, {uuid: uuid}, 'delete')
        .catch(e => {
            console.log('error', e)})
        .then(resp => {
            if(resp && resp.success && resp.result) {
                registrations.value = registrations.value.filter(reg => reg.uuid !== uuid);
            }
        })
        .finally(() => {
            registrationData();
        });
}
const competition = async () => {
    await apiRequest(`${EVENTS}/${competitionId.value}`, null, 'get')
        .catch(e => {
            console.log('error', e)
        })
        .then(resp => {
            if (resp && resp.success && resp.result) {
                event.value = resp.result
            }
        })
}

const calculateTotal = () => {
    if(registrations.value.length) {
        total.value = 0;
        total_discount.value = 0;
        let isAnyRegistrationHasDiscount = registrations.value.reduce((sum, item) => {
            return sum + (item.discount_price ? Number(item.discount_price) : 0);
        }, 0);
        registrations.value.forEach(el => {
            if(!el.is_paid) {
                total.value += parseFloat(el?.direction_category_price)
                if(isAnyRegistrationHasDiscount) {
                    if(el.discount_price) {
                        total_discount.value += parseFloat(el.discount_price)
                        discount_info.value = JSON.parse(el.discount_info);
                    } else {
                        total_discount.value += parseFloat(el?.direction_category_price)
                    }
                }
            }
        })
    }
};

emitter.on('delete-registration', (data) => {
    if(data?.type === 'payment') {
        deleteRegistrationPayment(data.uuid);
    }
});

watch([registrations, event], calculateTotal, { deep: true });

</script>
