<template>
    <div 
        :class="[
            'mt-5 rounded-3xl shadow-base bg-white', 
            isMinHeight ? 'p-7' : 'p-10', 
            'max-w-[1200px] w-full mx-auto'
        ]" 
        v-if="!isEmpty"
    >
        <slot />
    </div>
    <div 
        :class="[
            'mt-5 !p-0', 
            isMinHeight ? 'p-7' : 'p-10', 
            'max-w-[1200px] w-full mx-auto'
        ]" 
        v-else
    >
        <slot />
    </div>
</template>


<script setup>
import store from "@/store";
import {ref} from "vue";

const props = defineProps({
    isEmpty: {
        type: Boolean,
        default: false,
    },
})

const isMinHeight = ref(store.state.isMinHeight);

</script>