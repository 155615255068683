<template>
    <wrap-section class="mt-5">
        <div class="flex justify-between">
            <div class="flex">
                <section-header header="Мои Тренеры"/>
                <div
                    class="text-center text-lg flex place-content-center sm:place-content-start font-bold text-icon-base ml-5">
                    {{ trainers.length }}
                </div>
            </div>
            <!--            <div class="cursor-pointer text-sm text-base-color hidden sm:block mt-1" @click="navigateTo('sportsmans')">-->
            <!--                Посмотреть всех-->
            <!--            </div>-->
        </div>
        <div class="mt-5 flex items-start gap-2 flex-wrap justify-between">
            <div class="items-center flex flex-col cursor-pointer h-32 w-32" v-for="trainer in trainers">
                <div class="w-16 h-16">
                    <img class="rounded-full w-full h-full" :src="getAvatar(trainer)" alt=""/>
                </div>
                <div class="text-center text-black text-xs font-normal mt-3">
                    {{ trainer.name + ' ' + trainer.surname }}
                </div>
            </div>
            <!--            <div class="d_center flex-col cursor-pointer h-32 w-32 bg-light-base rounded-2xl border border-solid border-light-border"-->
            <!--                 @click="navigateTo('addNewSportsman')"-->
            <!--                 v-if="!store.state.sportsman.length"-->
            <!--            >-->
            <!--                <icon icon-name="plus" />-->
            <!--                <div class="text-center text-base-color">-->
            <!--                    Добавить спортсменов-->
            <!--                </div>-->
            <!--            </div>-->
        </div>
        <!--        <div class="text-sm text-base-color sm:hidden mt-1" @click="navigateTo('sportsmans')">-->
        <!--            Посмотреть всех-->
        <!--        </div>-->

    </wrap-section>

</template>

<script setup>
import WrapSection from "@/views/components/helpers/wrapSection.vue";
import SectionHeader from "@/views/components/dashboard/sectionHeader.vue";
import {apiRequest, getAvatar} from "@/utils/helpers";
import {onMounted, ref} from "vue";
import {TRAINERS} from "@/router/api";

const trainers = ref([]);

const getTrainers = () => {

    apiRequest(TRAINERS, {}, 'get')
        .catch(e => {
            console.log('error', e)
        })
        .then(resp => {
            if (resp && resp.success && resp.result?.length) {
                trainers.value = resp.result;
                return resp;
            }
        })
}
onMounted(async () => {
    await getTrainers();
});

</script>

<style scoped>

</style>

