<template>
    <dashboardLayout>
        <navigation-container class="mt-10">

            <login-as-user v-if="store.state.roles.includes('super')"/>

            <wrap-section>
                <div class="w-full flex flex-col sm:flex-row">

                    <div class="ml-3 cursor-pointer sm:hidden text-center mb-4 text-base-color"
                         @click="navigateTo('profile')">
                        Редактировать профиль
                    </div>

                    <div class="d_center">
                        <img :src="store.getters.getUserAvatar.url" alt=""
                             class="w-28 h-28 md:w-32 md:h-32 rounded-full mw-unset">
                    </div>

                    <div class="mt-4 sm:mt-0 sm:ml-8 flex card_info w-full">
                        <div class="">
                            <div class="text-center sm:text-left font-bold text-2xl flex">
                                <div class="flex flex-nowrap place-content-center sm:place-content-start">
                                    {{ store.getters.getUserFio }}
                                    <div class="d_center">
                                        <validate-modal/>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="text-base flex place-content-center sm:place-content-start mt-3 roles font-bold">
                                {{ store.getters.getUserRoles.map(el => getRoleTitle(el) ?? el).join(', ') }}
                            </div>
                            <div class="text-base flex place-content-center sm:place-content-start mt-3">
                                <div class="d_center mr-2">
                                    <icon icon-name="figure"/>
                                </div>
                                <div class="mr-2">
                                    <div v-if="store.getters.getUserGender === 'male'">Муж.,</div>
                                    <div v-else>Жен.,</div>
                                </div>
                                <div>{{ calculateAge(store.getters.getUserBirthday) }}</div>
                            </div>
                        </div>
                        <div>
                            <div class="ml-3 cursor-pointer text-sm text-base-color hidden sm:block mt-1"
                                 @click="navigateTo('profile')">
                                Редактировать профиль
                            </div>
                        </div>
                    </div>


                </div>
            </wrap-section>

            <my-sportsman v-if="store.state?.permissions?.includes('public_view_my_sportsmen')"/>

            <my-trainers v-if="store.state?.permissions?.includes('public_view_my_trainer')"/>

            <wrap-section class="mt-5" v-if="store.state?.permissions?.includes('public_trainer_my_events')">
                <div class="flex justify-between">
                    <section-header class="text-dark-base" header="Мои предстоящие соревнования"/>
                    <div class="cursor-pointer text-sm text-base-color hidden sm:block mt-1"
                         @click="navigateTo('my-competitions')">Посмотреть все
                    </div>
                </div>
                <competitionCardSmall v-for="(el, index) in upcomingEvents?.data"
                                      :key="index"
                                      :data="el"
                                      class="mt-5 cursor-pointer"
                                      @click="router.push({name: 'show-competition', params: {id: el.uuid}})"
                />
            </wrap-section>

        </navigation-container>
    </dashboardLayout>
</template>

<script setup>
import store from "@/store";
import dashboardLayout from "@/views/layout/dashboard";
import WrapSection from "@/views/components/helpers/wrapSection.vue";
import Icon from "@/views/components/helpers/icon.vue";
import MySportsman from "@/views/components/dashboard/mySportsman.vue";
import SectionHeader from "@/views/components/dashboard/sectionHeader.vue";
import NavigationContainer from "@/views/layout/components/navigation-container.vue";
import ValidateModal from "@/views/components/modal/validateModal.vue";
import competitionCardSmall from "@/views/components/competitions/competitionsCardSmall.vue";
import useEmitter from "@/utils/useEmitter";
import {apiRequest, calculateAge, navigateTo, getRoleTitle, uuid} from "@/utils/helpers";
import {onMounted, ref, computed, watch} from "vue";
import router from "@/router/router";
import {getUpcomingEvents} from "@/utils/helpers";
import InputCustom from "@/views/components/inputComponent/inputCustom.vue";
import LoginAsUser from "@/views/pages/dashboard/loginAsUser.vue";
import MyTrainers from "@/views/components/dashboard/myTrainers.vue";

const emitter = useEmitter();

const upcomingEvents = ref([]);

onMounted(() => {
    upcomingEvents.value.data = store.state.upcomingEvents;
    getUpcomingEvents(1, true);
});

// // вывод первых пяти мерориятий
// const firstFiveEvents = computed(() => {
//     return upcomingEvents.value.data ? upcomingEvents.value.data.slice(0, 5) : [];
// });

emitter.on('upcomingEvents', value => {
    if(value.data) {
        upcomingEvents.value.data = value.data;
        upcomingEvents.value.pagination = value.pagination;
    }
})

</script>

<style scoped>
.mw-unset {
    max-width: unset;
}

.add_sport_title {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    color: var(--dark-base);
}

.add_sport {
    border-radius: 15px;
    border: 1px solid #DDE1E7;
    background: #F7FAFF;
    height: 110px;
}

.add_sport div {
    font-size: 14px;
    font-weight: 500;
    color: var(--base-color);
}

.card_info {
    justify-content: space-between;
}

.roles {
    font-size: 16px;
    color: var(--dark-base);
}

.wrap_dashboard {
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (max-width: 960px) {
    .wrap_dashboard {
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

@media (max-width: 640px) {
    .profile_update {
        display: none;
    }

    .card_info {
        justify-content: center;
    }
}


</style>
