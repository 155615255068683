<template>

    <div v-if="store.state.discount" class="w-full" v-for="(el, index) in store.state.discount" :id="index">
        <EarlyRegOfferItem :data="el" :index="index" :priceType="priceType"/>
    </div>



    <div class="w-full flex mt-3 justify-end">
        <div class="flex cursor-pointer text-base-color" @click="addExtraItem">
            <div class="d_center">
                <icon icon-name="plus" class="mr-3"/>
            </div>
            Добавить акцию
        </div>

    </div>

    <alerts alertType="earlyRegOffer"/>

    <button class="btn mt-8 btn_blue w-full sm:w-64 d_center"
            @click="saveCompetitionCondition" :disabled="isLoading">
        <icon icon-name="spinner_white" class="mr-3" v-if="isLoading"/>
        Сохранить
    </button>

</template>

<script setup>
import {onMounted, ref, watch} from "vue";
import useEmitter from "@/utils/useEmitter";
import Icon from "@/views/components/helpers/icon.vue";
import {useRoute} from "vue-router";
import Alerts from "@/views/components/helpers/alerts.vue";
import {apiRequest} from "@/utils/helpers";
import {EVENTS} from "@/router/api";
import EarlyRegOfferItem from "@/views/components/createCompetition/discount/earlyRegOfferItem.vue";
import store from "@/store";

const props = defineProps({
    data: {
        type: Object,
        default: () => ({})
    },
    offerType: {
        type: String,
        default: 'qnt_reg'
    }
})

const priceType = ref([]);
const route = useRoute();
const emitter = useEmitter();
const isLoading = ref(false);
const onMountUpdate = ref(true);
const fd = ref({
    price_type: {},
    type: 'early_reg',
    items: [],
});


const clearDiscount = () => {
    if (onMountUpdate.value) return;
    fd.value.discount = null;
    fd.value.discount_price = null;
}

const addExtraItem = () => {
    let index = store.state.discount.length - 1;
    if (
        !store.state.discount[index].offer_end_at ||
        !store.state.discount[index].price_type ||
        !store.state.discount[index].offer_start_at ||
        !store.state.discount[index].discount
    ) {
        emitter.emit("alert", {
            alertType: 'earlyRegOffer',
            message: "Для добавления дополнительной акции, заполните все поля в текущей",
            isSuccess: 'false'
        });
    } else {
        storeStatePush();
    }
}

const validation = () => {
    let index = store.state.discount.length - 1;
    let base = 'Проверьте корректность ввода поля '
    if (!store.state.discount[index].offer_start_at) return emitter.emit("alert", {
        alertType: 'earlyRegOffer',
        message: base + ' Дата начала'
    });
    if (!store.state.discount[index].offer_end_at) return emitter.emit("alert", {
        alertType: 'earlyRegOffer',
        message: base + ' Дата окончания'
    });
    if (!store.state.discount[index].price_type) return emitter.emit("alert", {alertType: 'earlyRegOffer', message: base + ' Выберите цену'});
    if (!store.state.discount[index].discount) return emitter.emit("alert", {
        alertType: 'earlyRegOffer',
        message: base + ' Процент  скидки'
    });
    return true;
}

const saveCompetitionCondition = () => {
    if (validation()) {
        isLoading.value = true;
        fd.value.items = store.state.discount;

        apiRequest(EVENTS + '/' + route.params.id, fd.value, 'put')
            .then(resp => {
                if (resp && resp.success && resp.message) {
                    emitter.emit("success", {alertType: 'earlyRegOffer', message: resp.message});
                }
            })
            .catch(e => {
                if (e.message) {
                    emitter.emit("alert", {alertType: 'earlyRegOffer', message: e.message});
                } else {
                    emitter.emit("alert", {
                        alertType: 'earlyRegOffer',
                        message: 'Ошибка сервера! Обратитесь в поддержку или повторите позже.'
                    });
                }
                console.log('error', e)
            })
            .finally(() => {
                isLoading.value = false;
            });

    }
}

const storeStatePush = () => {
    store.state.discount.push(
        {
            price_type: {},
            offer_start_at: null,
            offer_end_at: null,
            discount: null,
            discount_price: null,
            is_all_category: false,
        }
    )
}

const updatePriceValue = newValue => {
    if (newValue.price) {
        clearDiscount();
        priceType.value = [
            {
                id: 'all',
                price: 'all',
                title: 'Все категории'
            },
            ...newValue.price
                .filter(item => item.direction_category?.title)
                .map(item => ({
                    id: item.id,
                    price: item.price,
                    title: `${new Intl.NumberFormat('ru-RU')
                        .format(parseFloat(item.price))} ₽ ${item.direction_category.title}`
                }))
        ];
    }
};


onMounted(() => {
    updatePriceValue(props.data);

    if(props.data.discounts) {
        props.data.discounts.forEach((el, i) => {
            store.commit('updateDiscount', {
                index: i,
                discountData: {
                    offer_end_at: el.end_date,
                    price_type: getPriceValue(el),
                    offer_start_at: el.start_date,
                    discount: el.discount_percent,
                    discount_price: el.discount_value,
                    is_all_category: el.is_all_categories
                }
            });
        });
    }
});

const getPriceValue = (value) => {
    if(value.is_all_categories) {
        return {
            id: 'all',
            price: 'all',
            title: 'Все категории'
        };
    }
   return priceType.value.find(item => item.id === value.price_id);
}

watch(
    () => props.data,
    (newValue) => {
        if (onMountUpdate.value) return;
        updatePriceValue(newValue);
    },
    {deep: true}
);
watch(
    () => fd.value.price_type,
    () => {
        clearDiscount();
        fd.value.is_all_category = fd.value.price_type?.id === 'all';
    },
    {deep: true}
);


</script>