<template>

    <div class="w-full h-0.5 bg-light-border mt-5 mb-5" v-if="index > 0" />

    <div class="w-full">
        <div class="wrap_age_gender mt-4">
            <date-picker v-model="fd.offer_start_at" label="Дата начала*" label-class="sm:w-32 font-bold"
                         :future="true" :type="'regStartAt_' + props.index"/>
            <div class="mt-4 gender sm:mt-0 sm:flex sm:justify-center">
                <date-picker v-model="fd.offer_end_at" label="Дата окончания*"
                             label-class="sm:w-32 font-bold" :future="true" :type="'regEndAt_' + props.index"/>
            </div>
        </div>
    </div>

    <div class="w-full">
        <div class="wrap_input mt-5" v-bind="$attrs">
            <div class="label-personal-page font-bold text-sm flex justify-normal items-center">Выберите цену</div>
            <VueMultiselect
                v-model="fd.price_type"
                :options="props.priceType"
                track-by="id"
                label="title"
                deselect-label="Удалить"
                select-label="Выбрать"
                selected-label="Выбрано"
                :multiple="false"
                placeholder="Не выбрана"
            >
                <template v-slot:noResult class="no-result">
                    <span>Тип акции не выбран</span>
                </template>
            </VueMultiselect>

        </div>
    </div>

    <div class="w-full">
        <div class=" mt-4 block sm:flex justify-between">


            <input-percent label="Процент скидки*" v-model="fd.discount" class="mt-4 mob_margin"
                           labelClass="pass-update" input-class="sm:w-44"/>

            <input-custom label="Цена со скидкой*" type="text" input-class="sm:w-44"
                          v-model="fd.discount_price" class="mt-4 mob_margin" labelClass="pass-update"
                          disabled
            />
        </div>
    </div>

</template>

<script setup>
import {onMounted, ref, watch} from "vue";
import useEmitter from "@/utils/useEmitter";
import {useRoute} from "vue-router";
import VueMultiselect from "vue-multiselect";
import DatePicker from "@/views/components/inputComponent/datePicker.vue";
import InputCustom from "@/views/components/inputComponent/inputCustom.vue";
import InputPercent from "@/views/components/inputComponent/inputPercent.vue";
import store from "@/store";

const props = defineProps({
    index: {
        type: Number,
        default: 0,
    },
    priceType: {
        type: Array,
        default: [],
    },
    data: {
        type: Object,
        default: () => ({})
    },
})

const route = useRoute();
const emitter = useEmitter();
const isLoading = ref(false);
const onMountUpdate = ref(true);
const fd = ref({
    price_type: {
        id: '',
        price: '',
        title: ''
    },
    type: 'early_reg',
    offer_start_at: '',
    offer_end_at: '',
    discount: null,
    discount_price: null,
    is_all_category: false,
});

const clearDiscount = () => {
    if(onMountUpdate.value) return ;
    fd.value.discount           = null;
    fd.value.offer_end_at       = null;
    fd.value.offer_start_at     = null;
    fd.value.discount_price     = null;
    fd.value.price_type         = null;

}

onMounted(() => {
    clearDiscount();
    fd.value.discount       = store.state.discount[props.index].discount;
    fd.value.offer_end_at   = store.state.discount[props.index].offer_end_at;
    fd.value.offer_start_at = store.state.discount[props.index].offer_start_at;
    fd.value.discount_price = store.state.discount[props.index].discount_price;
    fd.value.price_type     = store.state.discount[props.index].price_type;

    onMountUpdate.value = false;
});


emitter.on('regStartAt_' + props.index, value => {
    if (!value) return;
    fd.value.offer_start_at = value;
});
emitter.on('regEndAt_' + props.index, value => {
    if (!value) return;
    fd.value.offer_end_at = value;
});

watch(
    fd,
    () => {
        if(onMountUpdate.value) return ;
        if(fd.value.price_type.id === 'all') {
            fd.value.is_all_category = true;
            fd.value.discount_price = null;
        }

        if (fd.value.price_type?.price && fd.value.discount) {
            const price = parseFloat(fd.value.price_type.price);
            const discount = parseFloat(fd.value.discount);
            if (!isNaN(price) && !isNaN(discount)) {
                const discountedPrice = Math.round(price * (1 - discount / 100));
                fd.value.discount_price = new Intl.NumberFormat('ru-RU').format(discountedPrice) + " ₽";
            }
        }
        store.state.discount[props.index].offer_end_at      = fd.value.offer_end_at;
        store.state.discount[props.index].price_type        = fd.value.price_type;
        store.state.discount[props.index].offer_start_at    = fd.value.offer_start_at;
        store.state.discount[props.index].discount          = fd.value.discount;
        store.state.discount[props.index].discount_price    = fd.value.discount_price;
        store.state.discount[props.index].is_all_category   = fd.value.is_all_category;
    },
    { deep: true }
);

</script>