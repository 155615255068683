<template>

    <div class="w-full">
        <div class="wrap_input mt-5" v-bind="$attrs">
            <div class="label-personal-page font-bold text-sm flex justify-normal items-center">Тип</div>
            <VueMultiselect
                v-model="fd.type"
                :options="offerType"
                track-by="id"
                label="title"
                deselect-label="Удалить"
                select-label="Выбрать"
                selected-label="Выбрано"
                :multiple="false"
                placeholder="Не выбрана"
            >
                <template v-slot:noResult class="no-result">
                    <span>Тип акции не выбран</span>
                </template>
            </VueMultiselect>

        </div>
    </div>

    <div v-if="fd.type?.slug === 'early_reg'">
        <early-reg-offer :data="data" offer-type="early_reg"/>
    </div>
    <div v-if="fd.type?.slug === 'qnt_reg'">
        <qnt-registration :data="data" offer-type="qnt_reg"/>
    </div>
    <div v-if="fd.type?.slug === 'few_reg_sportsman'">
        <few-registration :data="data" offer-type="few_reg_sportsman"/>
    </div>

</template>

<script setup>
import {ref, watch} from "vue";
import useEmitter from "@/utils/useEmitter";
import {useRoute} from "vue-router";
import VueMultiselect from "vue-multiselect";
import EarlyRegOffer from "@/views/components/createCompetition/discount/earlyRegOffer.vue";
import QntRegistration from "@/views/components/createCompetition/discount/qntRegistration.vue";
import FewRegistration from "@/views/components/createCompetition/discount/FewRegistration.vue";

const props = defineProps({
    data: {
        type: Object,
        default: {},
    },
})

const offerType = ref([]);
const route = useRoute();
const emitter = useEmitter();
const isLoading = ref(false);
const fd = ref({
    type: {},
    conditions_info: '',
    offer: '',
});

watch(
    () => props.data,
    (newValue) => {
        if(newValue.discount_types) {
            offerType.value = newValue.discount_types;
        }
        if (newValue.discounts && newValue.discounts[0]) {
            if (!fd.value.type) {
                fd.value.type = {};
            }
            fd.value.type.slug      = newValue.discounts[0]?.discount_type?.slug;
            fd.value.type.title     = newValue.discounts[0]?.discount_type?.title;
            fd.value.type.id        = newValue.discounts[0]?.discount_type?.id;
        }
    },
    { deep: true }
);

</script>