<template>
    <dashboardLayout>
        <navigation-container class="mt-10">
            <wrap-section class="mt-10" v-if="paymentSuccess">
                <h1 class="text-left">
                    Благодарим за регистрацию и оплату!
                </h1>
                <br>
                <h3>
                    Мы счастливы, что вы стали частью спортивного события. Вас ждет незабываемый день, полный эмоций и достижений! Подготовьтесь как следует и возьмите с собой ваше стремление к победе — оно будет вашим преимуществом.
                    <br><br>
                    До скорой встречи на соревнованиях! Желаем вам удачи и ярких результатов!
                </h3>

                <h2 class="mt-10 font-bold text-xl">
                    Остался последний шаг — загрузите документы спортсменов для участия в соревнованиях
                </h2>
                <div class="flex justify-center">
                    <button class="btn mt-3 btn_blue w-full sm:w-64" @click="uploadDoc">
                        Загрузить документы
                    </button>
                </div>




            </wrap-section>
        </navigation-container>
    </dashboardLayout>
</template>

<script setup>
import WrapSection from "@/views/components/helpers/wrapSection.vue";
import {onMounted, ref} from "vue";
import router from "@/router/router";
import dashboardLayout from "@/views/layout/dashboard";
import NavigationContainer from "@/views/layout/components/navigation-container.vue";
import {useRoute} from "vue-router";
import {apiRequest} from "@/utils/helpers";
import {EVENT_PAYMENT_SUCCESS} from "@/router/api";

const paymentSuccess = ref(false);
let event_uuid = ref(false);
const uploadDoc = () => {
    router.push({name: 'file-upload', params: {id: event_uuid.value}});
}

onMounted(async () => {
    const route = useRoute();
    const uuid = route.query.uuid;

    if (uuid) {
        try {
            apiRequest(EVENT_PAYMENT_SUCCESS + "?uuid=" + uuid,[], 'post')
                .then(resp => {
                    if (resp.error) {
                        console.log(resp)
                    }
                    if (resp.success) {
                        paymentSuccess.value = true;
                        event_uuid.value = resp.result.event?.uuid;
                    }
                })
                .catch(e => {
                    console.log(e)
                })
        } catch (error) {

        }
    }
});


</script>
