<template>
    <div class="inline-flex w-fit rounded-md border-solid border-dark-base border-2">
      <button 
        v-for="tab in tabs" 
        :key="tab.name"
        @click="handleTabChange(tab)"
        :class="{
          'bg-dark-base text-light-base': tab.isActive,
          'text-dark-base': !tab.isActive
        }"
        class="py-2 px-4 text-sm font-semibold focus:outline-none rounded-none"
        :style="{ width: tabWidth }"
      >
        {{ tab.label }}
      </button>
    </div>
</template>

<script setup>
import {onMounted, ref, watch, defineProps, defineEmits} from "vue";

const props = defineProps({
  tabs: {
    type: Array,
    required: true
  }
});

const emit = defineEmits(['change']);
const tabWidth = ref('auto');

const handleTabChange = (tab) => {
    // сбросить активность с других вкладок
    props.tabs.forEach(t => t.isActive = false);

    // установить активной выбранную вкладку
    tab.isActive = true;

    // эмитировать изменение вкладки
    emit('change', tab.value);
};

onMounted(() => {
  // все кнопки вкладок
  const tabButtons = document.querySelectorAll('.inline-flex button');
  
  // макс ширина
  let maxWidth = 0;
  tabButtons.forEach(button => {
    const buttonWidth = button.offsetWidth;
    if (buttonWidth > maxWidth) {
      maxWidth = buttonWidth;
    }
  });

  // максимальная ширина для всех вкладок
  tabWidth.value = `${maxWidth}px`;
});


</script>