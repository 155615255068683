<template>
    <baseLayout>
        <div class="wrap_login d_center">
            <div class="login_form sm:w-full">
                <h1>Войти в личный кабинет</h1>

                <input-custom label-class="min-w-24" label="Email" type="text" v-model="email" class="mt-5"/>

                <input-custom label-class="min-w-24" label="Пароль" type="password" v-model="password" class="mt-4"/>

                <div class="forgot_pass" @click="navigateTo('passwordRecovery')">
                    Забыли пароль?
                </div>

                <alerts/>

                <button class="btn mt-3 btn_blue bg-amber-100 w-full" @click="loginUser">
                    Войти
                </button>
                <button class="btn mt-3 btn_white w-full" @click="navigateTo( 'registration')">
                    Зарегистрироваться
                </button>

            </div>
        </div>
    </baseLayout>
</template>

<script setup>
import baseLayout from "@/views/layout/base";
import {LOGIN, REGISTER, SANCTUM} from "@/router/api";
import axios from "axios";
import Alerts from "@/views/components/helpers/alerts.vue";
import {ref, onMounted, watch} from "vue";
import InputCustom from "@/views/components/inputComponent/inputCustom.vue";
import useEmitter from "@/utils/useEmitter";
import store from "@/store";
import {apiRequest, navigateTo, authStatus} from "@/utils/helpers";

const email = ref('');
const password = ref('');
const emitter = useEmitter();



watch(
    () => store.state.isAuthenticated,
    (newValue) => {
        if (newValue) {
            window.location.href = '/dashboard';
        } else {
            console.log('Пользователь не аутентифицирован.');
        }
    }
);

const verify = () => {
    if (email.value.length < 5 || !email.value.includes('@') || !email.value.includes('.')) {
        emitter.emit("alert", "Проверьте корректность ввода поля email");
        return false;
    }
    return true;
};
const loginUser = async () => {
    email.value = email.value.trim().toLowerCase();
    if (verify()) {
        try {
            const resp = await apiRequest(LOGIN, {
                email: email.value,
                password: password.value
            });

            if (resp.success) {
                window.location.href = '/dashboard';
            } else if (resp.error && resp.message) {
                emitter.emit("alert", resp.message);
            }
        } catch (e) {
            emitter.emit("alert", "Произошла ошибка при попытке входа. Пожалуйста, проверьте Email/Пароль и попробуйте снова.");
        }
    }
}

</script>

<style scoped>
.forgot_pass {
    margin-top: 15px;
    color: var(--base-color);
    text-align: right;
    display: flex;
    justify-content: right;
    cursor: pointer;
    text-decoration: underline;
}

</style>
