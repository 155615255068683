<template>
    <div class="h-full">
        <modal :visible="modalError">
            <div class="text-left text-danger-dark font-bold text-lg">Ошибка!</div>
            <div class="text-sm mt-5">{{ modalErrorMessage }}</div>
            <button class="btn btn_blue w-full mt-5" @click="modalError = false">Понятно</button>
        </modal>
        <div
            class="drop-area text-xs mi:w-32  p-2 h-full rounded-2xl bg-light-base"
            :class="{ 'drag-over': isDraggingOver }"
            @dragenter.prevent="handleDragEnter"
            @dragover.prevent="handleDragOver"
            @dragleave.prevent="handleDragLeave"
            @drop.prevent="handleDrop"
            ref="dropArea"
            v-if="!isUploaded"
        >

            <!-- <icon :icon-name="uploadType === 'audio' ? 'uploadMusic' : 'uploadAny'" class="mb-2"></icon> -->

                <icon 
                    :icon-name="(uploadType && icon === 'uploadArchive') 
                                ? 'uploadArchive' 
                                : ({
                                    'audio': 'uploadMusic',
                                    'medicalCertificate': 'uploadImage',
                                    'insurance': 'uploadImage',
                                    'permission': 'uploadImage',
                                    'elements': 'uploadDoc'
                                }[uploadType] || 'uploadAny')" 
                    class="mb-2">
                </icon>

            Перетащите 
            <span v-if="icon === 'uploadArchive'">архив</span>
            <span v-else>файл</span>
            или
            <!-- <span @click="openFileInput" class="cursor-pointer underline text-xs text-base-color"
                  :class="{ 'svg-over': isDraggingOver }">загрузите</span> -->

                  <span 
                    @click="openFileInput" 
                    class="cursor-pointer underline text-xs text-base-color"
                    :class="{ 'svg-over': isDraggingOver }">
                    {{
                        (uploadType && icon === 'uploadArchive')
                            ? 'загрузите .zip/.rar'
                            : ({
                                'audio': 'загрузите .mp3',
                                'medicalCertificate': 'загрузите .pdf/.jpg',
                                'insurance': 'загрузите .pdf/.jpg',
                                'permission': 'загрузите .pdf/.jpg',
                                'elements': 'загрузите .doc/.docx/.pdf'
                            }[uploadType] || 'загрузите')
                    }}
                    </span>

            <input type="file" @change="handleFileInput" ref="fileInput" :accept="fileAccept" class="hidden">

        </div>
        <div v-else class="text-xs mi:w-32  p-2 h-full rounded-2xl">
            <div class="flex justify-between">
                <!-- <icon :icon-name="uploadType === 'audio' ? 'uploadMusic' : 'uploadAny'" class="mb-2"></icon> -->
                <icon 
                    :icon-name="(uploadType && icon === 'uploadArchive') 
                                ? 'uploadArchive' 
                                : ({
                                    'audio': 'uploadMusic',
                                    'medicalCertificate': 'uploadImage',
                                    'insurance': 'uploadImage',
                                    'permission': 'uploadImage',
                                    'elements': 'uploadDoc'
                                }[uploadType] || 'uploadAny')" 
                    class="mb-2">
                </icon>

                <icon icon-name="closeBlueRing" class="cursor-pointer mb-2" @click="deleteDocument"
                      v-if="(props.data?.status !== 'moderating' && !isModerating) && props.data?.status !== 'active'"></icon>
            </div>
            <a :href="uploadData?.original_url" target="_blank" @click.prevent="handleFileClick(uploadData)"
               class="text-xs text-base-color">{{ uploadData?.short_file_name ?? '' }}</a>
        </div>
    </div>
</template>

<script setup>
import {onMounted, ref, watch} from 'vue';
import {apiRequest} from "@/utils/helpers";
import {SPORTSMANS_FILES_UPLOAD} from "@/router/api";
import {defineProps} from 'vue';
import useEmitter from "@/utils/useEmitter";
import Icon from "@/views/components/helpers/icon.vue";
import Modal from "@/views/components/modal/modal.vue";
import {useRoute} from "vue-router";

const emitter = useEmitter();
const props = defineProps({
    uploadType: String,
    data: {
        type: Object,
        default: false,
    },
    icon: {
        type: String,
        default: false,
    },
});
const route = useRoute();
const isDraggingOver = ref(false);
const fileInput = ref(null);
const dropArea = ref(null);
const fileAccept = ref('.doc,.docx');
const isUploaded = ref(false);
const uploadData = ref(null);
const modalError = ref(false);
const isModerating = ref(false);
const modalErrorMessage = ref("Ошибка загрузки. Перезагрузите страницу или попробуйте позднее.");

// const handlePropsChange = () => {
//     if (props.uploadType === 'audio') {
//         fileAccept.value = '.mp3';
//         if (props.data?.media_files?.audio?.file_name) {
//             isUploaded.value = true;
//             uploadData.value = props.data.media_files.audio;
//         }
//     }
//     if (props.uploadType === 'elements') {
//         if (props.data?.media_files?.element?.file_name) {
//             isUploaded.value = true;
//             uploadData.value = props.data.media_files.element;
//         }
//     }
//     if (props.uploadType === 'permission') {
//         if (props.data?.media_files?.permission?.file_name) {
//             isUploaded.value = true;
//             uploadData.value = props.data.media_files.permission;
//         }
//     }
// };

const handlePropsChange = () => {
    const fileTypesMap = {
        audio: { accept: '.mp3', field: 'audio' },
        elements: { accept: 'application/pdf, .doc, .docx', field: 'element' },
        medicalCertificate: { accept: 'application/pdf, image/jpeg, .jpg, .png', field: 'medicalCertificate' },
        insurance: { accept: 'application/pdf, image/jpeg, .jpg, .png', field: 'insurance' },
        permission: { accept: 'application/pdf, image/jpeg, .jpg, .png', field: 'permission' },
        uploadArchive: { accept: '.zip, .rar', field: 'uploadArchive'},
    };

    const fileType = fileTypesMap[checkArchive(props.uploadType)];

    if (fileType) {
        if(props.uploadType === 'uploadArchive') {
            fileAccept.value = fileTypesMap.uploadArchive.accept;
        } else {
            fileType.accept && (fileAccept.value = fileType.accept);
        }
        const fileData = props.data?.media_files?.[checkArchive(fileType.field)];
        fileData?.file_name && (isUploaded.value = true, uploadData.value = fileData);
    }

};

const checkArchive = (type) => {
    return type === 'uploadArchive' ? 'elements' : type;
}

onMounted(() => {
    handlePropsChange();
});

watch(() => props.uploadType, handlePropsChange);
watch(() => props.data, handlePropsChange, {deep: true});

const deleteDocument = async () => {

    await apiRequest(SPORTSMANS_FILES_UPLOAD + "/" + props.data.reg_uuid,
        {type: checkArchive(props.uploadType), reg_uuid: props.data.reg_uuid}, 'put')
        .catch(e => {
            console.error(e)
        })
        .then(async resp => {
            if (resp && resp.success) {
                emitter.emit("fileSportsmanUpload", resp.sportsman);
                emitter.emit("fileDelete", props.data.reg_uuid);
                isUploaded.value = false;
            }
        })

}

const handleDragEnter = (event) => {
    let parentElement = document.querySelector('.drop-area')
    if (parentElement.contains(event.relatedTarget)) {
        isDraggingOver.value = true;
    }

    isDraggingOver.value = true;
};

const handleDragOver = (event) => {
    event.preventDefault();
};

const handleDragLeave = (event) => {
    if (dropArea.value && !dropArea.value.contains(event.relatedTarget)) {
        isDraggingOver.value = false;
    }
};

const openFileInput = () => {
    fileInput.value.click();
};

const handleFileInput = (event) => {
    const files = event.target.files;
    if (files.length > 0) {
        handleFiles(files[0]);
    }
};

const handleDrop = (event) => {
    event.preventDefault();
    isDraggingOver.value = false;
    const files = event.dataTransfer.files;
    if (files.length > 0) {
        handleFiles(files[0]);
    }
};

const upload = async (file) => {
    let formData = new FormData();

    formData.append('type', checkArchive(props.uploadType));
    formData.append('reg_uuid', props.data.reg_uuid);
    formData.append('event_id', props.data.event_id);
    formData.append('file', file, file.name);
    if(route.meta.sportsmanRegistration) {
        formData.append('sportsman_registration', true);
    }

    await apiRequest(SPORTSMANS_FILES_UPLOAD, formData)
        .catch(e => {
            if (e.message && e.fails) {
                modalErrorMessage.value = e.message + " " + e.fails;
                modalError.value = true;
            }
        })
        .then(async resp => {
            if (resp && resp.success) {
                isUploaded.value = true;
                emitter.emit("fileSportsmanUpload", resp.result);
            }
        })
}

const handleFiles = (file) => {
    upload(file);
};
const handleFileClick = (file) => {
    const fileUrl = file.original_url;
    const fileExtension = fileUrl.split('.').pop().toLowerCase();

    if (fileExtension === 'mp3') {
        window.open(fileUrl, '_blank');
    } else {
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = file.file_name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
};

emitter.on('moderatingStatus', (uuid) => {
    if (props?.data?.reg_uuid === uuid) {
        isModerating.value = true;
    }
})

</script>

<style scoped>

.drop-area {
    border: 1px dashed var(--light-border);
}

.drag-over {
    border: 1px dashed var(--base-color);

}

.svg-over, .drag-over {
    color: var(--base-color);
    fill: var(--base-color);
}

</style>
