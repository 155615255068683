<template>
    <baseLayout>
         <div class="flex justify-center mb-10 ml-2 mr-2">
                <div class="w-full lg:w-auto">
                        <wrap-section :is-empty=true>
                            <div ref="tabsContainer" class="prof_container relative flex flex-col sm:flex-row sm:justify-between">
                                <div>
                                    <h2 class="text-2xl md:text-4xl ">Рейтинги</h2>
                                </div>
                                <div class="mt-2 sm:mt-0">
                                    <tab-switcher :tabs="tabs" @change="switchTab" />
                                </div>
                            </div>
                        </wrap-section>
                    <div>
                        <wrap-section :is-empty="true" v-if="currentTab === 'sportsman'">
                            <season-btn :current-age-group="currentAgeGroup" :ageGroup="1" btn-label="Общий рейтинговый зачет"   @click="sortAge(1)"/>
                            <season-btn :current-age-group="currentAgeGroup" :ageGroup="2" btn-label="до 7 лет"     class="ml-4" @click="sortAge(2)"/>
                            <season-btn :current-age-group="currentAgeGroup" :ageGroup="3" btn-label="8-11 лет"     class="ml-4" @click="sortAge(3)"/>
                            <season-btn :current-age-group="currentAgeGroup" :ageGroup="4" btn-label="12-17 лет"    class="ml-4" @click="sortAge(4)"/>
                            <season-btn :current-age-group="currentAgeGroup" :ageGroup="5" btn-label="18+"          class="ml-4" @click="sortAge(5)"/>
                        </wrap-section>

                        <winners-podium :data="winnersRating" />

                        <input-search v-model="search" @input="searchHandler" :width="'w-72'" :height="'h-8'" label="" class="mt-6" />

                        <div class="overflow-hidden rounded-2xl border border-solid border-light-border mt-6 relative">


                            <div v-if="isSticky" class="sticky-tab fixed w-auto overflow-x-auto bg-white z-10 inline sm:right-10 bottom-5 
                                                        left-1/2 transform -translate-x-1/2 sm:left-auto sm:translate-x-0">
                                <tab-switcher :tabs="tabs" @change="switchTab" />
                            </div>


                            <table class="min-w-full border-collapse table-auto bg-white shadow-lg">
                                <rating-header :activeTab="currentSortType"/>
                                <tbody>
                                <rating-element
                                    v-for="(element, index) in currentRating" :key="element.id"
                                    :data="element" :index="index"
                                    :show-position-change="currentAgeGroup === 1"
                                />
                                </tbody>
                            </table>

                        </div>
                        <wrap-section :is-empty=true>
                            <button class="btn btn_white mt-8 py-2.5 px-3 !text-sm d_center w-full" @click="showAll" v-if="showRatingBtn">
                                Показать еще
                            </button>
                        </wrap-section>

                    </div>

                </div>
            </div>
    </baseLayout>
</template>

<script setup>
import baseLayout from "@/views/layout/base";
import WrapSection from "@/views/components/helpers/wrapSection.vue";
import NavigationContainer from "@/views/layout/components/navigation-container.vue";
import InputSearch from "@/views/components/inputComponent/inputSearch.vue";
import RatingElement from "@/views/pages/dashboard/rating/ratingElement.vue";
import RatingHeader from "@/views/pages/dashboard/rating/ratingHeader.vue";
import {apiRequest} from "@/utils/helpers";
import {RATING} from "@/router/api";
import {ref, onMounted, onUnmounted} from "vue";
import WinnersPodium from "@/views/pages/dashboard/rating/winnersPodium.vue";
import useEmitter from "@/utils/useEmitter";
import tabSwitcher from "@/views/components/raiting/tabSwitcher.vue";
import SeasonBtn from "@/views/components/raiting/seasonBtn.vue";
// import PodiumItem from "@/views/components/raiting/podiumItem.vue";
// import SeasonBtn from "@/views/components/raiting/seasonBtn.vue";

const currentRating = ref([]);
const serverDataRating = ref({
    sportsman: [],
    trainer: [],
});
const winnersRating = ref([]);
const emitter = useEmitter();
const search = ref('');
const currentSortType = ref('score');
const currentAgeGroup = ref(1);
const currentSeason = ref(2024);
const showRatingBtn = ref(true);
const isShowAllClicked = ref(false);
const cutoffDate = new Date("2024-12-01");
const previewLimit = 20;

const isSticky = ref(false);
const tabsContainer = ref(null);

const handleScroll = () => {
  if (!tabsContainer.value) return;
  const { top } = tabsContainer.value.getBoundingClientRect();
  isSticky.value = top <= -100;
};

onMounted(() => {
  window.addEventListener("scroll", handleScroll);
});

onUnmounted(() => {
  window.removeEventListener("scroll", handleScroll);
});

// вкладки для переключения между спортсменами и тренерами
const tabs = ref([
    { label: 'Спортсмены', value: 'sportsman', isActive: true },
    { label: 'Тренеры', value: 'trainer', isActive: false }
]);
const currentTab = ref('sportsman');

const switchTab = (tab) => {
    currentTab.value = tab;
    resetRating();  // сбросить текущие данные и сортировку
    fetchRating(tab);  // запрос данных для соответствующей вкладки
};
const searchHandler = () => {
    if (!search.value || search.value.trim().length < 2) {
        resetRating(); // сброс рейтинга, если поле поиска очищено
        return;
    }

    const query = search.value.trim().toLowerCase();
    // Проверка на наличие данных перед фильтрацией
    const data = serverDataRating.value[currentTab.value] || [];
    currentRating.value = data.filter(item =>
        item.name.toLowerCase().includes(query) ||
        item.surname.toLowerCase().includes(query)
    );
    showRatingBtn.value = currentRating.value.length >= 20; // сокрытие кнопки, если результаты поиска менее 20
};

// сортировка только на основе текущего поля и ограничения
const updateRating = (field, limit = previewLimit, data = false) => {
    if (!data && currentAgeGroup.value !== 1) {
        currentAgeGroup.value = 1;
    }
    if (!data) data = serverDataRating.value[currentTab.value] || [];
    if (data.length === 0) return;


    const sortByFilterAndPosition = {
        score: (a, b) => a.position - b.position,
        gold: (a, b) => b.gold - a.gold || a.position - b.position,
        silver: (a, b) => b.silver - a.silver || a.position - b.position,
        bronze: (a, b) => b.bronze - a.bronze || a.position - b.position,
    };

    // сортировка по заданному полю
    const sortedData = [...data].sort(sortByFilterAndPosition[field]);

    // если "Показать все" не нажато, оставляем только первые 20 элементов
    if (isShowAllClicked.value) {
        currentRating.value = sortedData; // все элементы
        showRatingBtn.value = false; // скрыть кнопку "Показать ещё"
    } else {
        currentRating.value = sortedData.slice(0, limit); // только первые 20 элементов
        showRatingBtn.value = sortedData.length > limit; // отображение кнопки, если есть больше 20 элементов
    }
};

const showAll = () => {
    isShowAllClicked.value = true;
    if(currentAgeGroup.value !== 1) {
        let res = sortAge(currentAgeGroup.value, true);
        updateRating(currentSortType.value, Infinity, res)
        return false;
    }
    updateRating(currentSortType.value, Infinity);
}

// строс рейтинга к первым 20 элементам
const resetRating = () => {
    isShowAllClicked.value = false;
    updateRating(currentSortType.value, previewLimit);
    showRatingBtn.value = true;
};

const filterByAgeGroup = (ageGroup, needToReturn = false) => {

    const data = serverDataRating.value[currentTab.value] || [];

    if (data.length === 0) return;
    const calculateAge = (birthDate) => {
        const birth = new Date(birthDate);
        let age = cutoffDate.getFullYear() - birth.getFullYear();
        if (
            cutoffDate.getMonth() < birth.getMonth() ||
            (cutoffDate.getMonth() === birth.getMonth() && cutoffDate.getDate() < birth.getDate())
        ) {
            age--;
        }
        return age;
    };

    const sortedData = data.filter(person => {
        const age = calculateAge(person.birthday);
        return age >= ageGroup.min && age <= ageGroup.max;
    });
    if(!needToReturn) {
        currentSortType.value = ('score');
        showRatingBtn.value = true;
        winnersRating.value = sortedData.slice(0, 3);
        currentRating.value = sortedData.slice(0, previewLimit);
        return false;
    } else {
        return sortedData;
    }
};

const sortAge = (ageNum, needToReturn = false) => {
    currentAgeGroup.value = ageNum;
    switch (ageNum) {
        case 2:
            return filterByAgeGroup ({ min: 0, max: 7 }, needToReturn);
        case 3:
            return filterByAgeGroup ({ min: 8, max: 11 }, needToReturn);
        case 4:
            return filterByAgeGroup ({ min: 12, max: 17 }, needToReturn);
        case 5:
            return filterByAgeGroup ({ min: 18, max: Infinity }, needToReturn);
        default:
            return filterByAgeGroup ({ min: 0, max: Infinity }, needToReturn);

    }
};

const fetchRating = async (tab) => {
    try {
        const type = tab === 'sportsman' ? 'sportsman' : 'trainer'; // sportsman или trainer

        if (serverDataRating.value[type]?.length > 0) {
            winnersRating.value = serverDataRating.value[type].slice(0, 3);
            resetRating(); // обновление текущего рейтинга
            return;
        }

        // запрос только если данных нет
        const response = await apiRequest(`${RATING}?season=${currentSeason.value}&type=${type}`, null, 'get');

        if (response && response.success) {
            // локал обн данные
            serverDataRating.value[type] = response.result;
            winnersRating.value = response.result.slice(0, 3); // лидеры
            resetRating(); // обн весь рейтинг
        }
    } catch (error) {
        console.error(`Ошибка при загрузке рейтинга ${tab}:`, error);
    }
};

onMounted(() => {
    fetchRating(currentTab.value);
});


emitter.on('sort', value => {
    currentSortType.value = value;

    // кнопка "Показать все" не нажата, показываем только первые 20 элементов
    if (isShowAllClicked.value) {
        updateRating(value, Infinity); // все элементы при изменении сортировки
    } else {
        updateRating(value, 20);  // только 20 элементов при изменении сортировки
    }

    // повторный запрос данных для текущей вкладки после сортировки
    fetchRating(currentTab.value);
});


</script>


