<template>
    <dashboardLayout>
        <navigation-container class="mt-10">
            <wrap-section :is-empty=true >
                <h2 class="text-2xl md:text-4xl ">Календарь</h2>
            </wrap-section>
            <div v-if="store.state?.permissions?.includes('public_view_public_events')">
                <wrap-section :is-empty=true class="mx-auto py-4 grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <calendar-item :data="el" v-for="el in upcomingEvents.data" :key="el.uuid"/>
                </wrap-section>
                <wrap-section :is-empty=true class="mx-auto py-4 grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <pagination  :model-value="upcomingEvents.paginate" :meta="upcomingEvents.pagination"/>
                </wrap-section>
            </div>
            <wrap-section class="!bg-danger-light" v-else>
                <div class="text-center">
                    У вас нет прав на просмотр соревнований
                </div>
            </wrap-section>

        </navigation-container>
    </dashboardLayout>
</template>

<script setup>

import store from "@/store";
import dashboardLayout from "@/views/layout/dashboard";
import WrapSection from "@/views/components/helpers/wrapSection.vue";
import NavigationContainer from "@/views/layout/components/navigation-container.vue";
import {useRoute} from 'vue-router';
import Pagination from "@/views/components/helpers/pagination.vue";
import {apiRequest} from "@/utils/helpers";
import {EVENTS} from "@/router/api";
import {onMounted, ref} from "vue";
import CalendarItem from "@/views/pages/dashboard/calendar/calendarItem.vue";

const route = useRoute();
const upcomingEventsCurrentPage = ref(1);
const upcomingEvents = ref({});
const getUpcomingEvents = () => {
    apiRequest(EVENTS + "?event_time=future&active=true&page=" + upcomingEventsCurrentPage.value, null, 'get')
        .catch(e => {
            console.log('error', e)
        })
        .then(resp => {
            if (resp && resp.success) {
                upcomingEvents.value.data = resp.result.data;
                upcomingEvents.value.pagination = resp.result.pagination;
            }
        })
}

onMounted(() => {
    getUpcomingEvents();
});






</script>
