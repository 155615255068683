<template>
    <div class="w-full flex justify-end">
        <button class="bg-danger-light rounded-xl" @click="deleteRegistration()">
            <icon icon-name="delete"/>
        </button>
    </div>
</template>

<script setup>
import Icon from "@/views/components/helpers/icon.vue";
import useEmitter from "@/utils/useEmitter";
import {ref} from "vue";
const emitter = useEmitter();
const props = defineProps({
    uuid: {
        type: String,
        default: false,
    },
    type: {
        type: String,
        default: 'res',
    }
})
const deleteRegistration = () => {
     emitter.emit('delete-registration', {uuid: props.uuid, type: props.type});
};

</script>